import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function AuthenticatedRoute({ component, path }) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!isAuthenticated) {
    // redirect to home if not logged in
    return <Redirect to="/" />;
  }

  return <Route path={path} component={component} />;
}

export default AuthenticatedRoute;
