import React from 'react';

import DailyAnchorContainer from '../DailyAnchor/DailyAnchorContainer';
import FreeDailyAnchorComponent from '../FreeDailyAnchor/FreeDailyAnchorComponent';

function DailyAnchorPage() {
  // if inactive show freedailyanchor
  //if(userAccountInactive) {
  //return <FreeDailyAnchorComponent />;
  //}
  return <DailyAnchorContainer />;
}

export default DailyAnchorPage;
