import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';

function StatsComponent({ stats }) {
  if (!stats) {
    return null;
  }

  const { mind, mellow, move, thisWeek } = stats?.stats;
  const totalCount = stats?.totalCount[0].count;

  function renderAnchors() {
    if (!stats.anchors.length) {
      return <div>No anchors</div>;
    }

    return stats.anchors.map((anchor) => (
      <Card>
        <Date>{anchor.submittedDate}</Date>
        <ContentWrap>
          <Content done={anchor.moveDone}>
            Move
            {anchor.moveDone && <TickIcon icon={faCheck} />}
          </Content>
          <Content done={anchor.mindDone}>
            Mind
            {anchor.mindDone && <TickIcon icon={faCheck} />}
          </Content>
          <Content done={anchor.mellowDone}>
            Mellow
            {anchor.mellowDone && <TickIcon icon={faCheck} />}
          </Content>
        </ContentWrap>
        <CardTitle>Gratitude</CardTitle>
        <ListGroup variant="flush">
          {anchor.gratitudeOne && (
            <ListGroup.Item>{anchor.gratitudeOne}</ListGroup.Item>
          )}
          {anchor.gratitudeTwo && (
            <ListGroup.Item>{anchor.gratitudeTwo}</ListGroup.Item>
          )}
          {anchor.gratitudeThree && (
            <ListGroup.Item>{anchor.gratitudeThree}</ListGroup.Item>
          )}
        </ListGroup>
        <CardTitle>Good</CardTitle>
        <ListGroup variant="flush">
          {anchor.goodOne && <ListGroup.Item>{anchor.goodOne}</ListGroup.Item>}
          {anchor.goodTwo && <ListGroup.Item>{anchor.goodTwo}</ListGroup.Item>}
          {anchor.goodThree && (
            <ListGroup.Item>{anchor.goodThree}</ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    ));
  }

  return (
    <Wrap>
      <Title>Anchor stats</Title>
      <StatWrap>
        <Stat>{totalCount}</Stat>
        <StatType>Anchors all time</StatType>
      </StatWrap>
      <StatWrap>
        <Stat>{thisWeek}</Stat>
        <StatType>Anchors this week</StatType>
      </StatWrap>
      <Subtitle>Last 7 days</Subtitle>
      <GroupWrap>
        <StatWrap>
          <Stat>{move}</Stat>
          <StatType>Move</StatType>
        </StatWrap>
        <Padding />
        <StatWrap>
          <Stat>{mind}</Stat>
          <StatType>Mind</StatType>
        </StatWrap>
        <Padding />
        <StatWrap>
          <Stat>{mellow}</Stat>
          <StatType>Mellow</StatType>
        </StatWrap>
      </GroupWrap>
      <CardWrap>{renderAnchors()}</CardWrap>
    </Wrap>
  );
}

export default StatsComponent;

const Wrap = styled.div`
  @media (min-width: 575px) {
    justify-content: center;
    margin: 60px auto;
    max-width: 600px;
  }
`;

const Title = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  text-align: center;
  padding-bottom: 25px;
  padding-top: 20px;
`;

const Subtitle = styled.div`
  font-size: 1.25em;
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Padding = styled.div`
  padding: 0 22px;
`;

const StatWrap = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const Stat = styled.div`
  font-size: 4em;
  font-weight: 700;
`;

const StatType = styled.div`
  font-size: 0.75em;
`;

const GroupWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

const CardWrap = styled.div`
  @media (min-width: 575px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 20px;
  margin: 20px;
  border-radius: 5px;

  @media (min-width: 575px) {
    width: 300px;
  }
`;

const CardTitle = styled.div`
  text-align: center;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: 23px;
`;

const Date = styled.div`
  text-align: center;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 23px;
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  font-size: 0.75em;
`;

const ContentWrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const Content = styled.div`
  margin-right: 8px;
  color: ${({ done }) => (done ? 'green;' : 'black;')};
`;
