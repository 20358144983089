import React from 'react';
import styled from 'styled-components';

function SignUp() {
  return (
    <>
      <div id="mc_embed_signup">
        <form
          action="https://gmail.us7.list-manage.com/subscribe/post?u=ea72f7c5bb4936598e5df3a54&amp;id=18075db5fc"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          noValidate
        >
          <Wrap id="mc_embed_signup_scroll">
            <EmailInput
              type="email"
              name="EMAIL"
              class="email"
              id="mce-EMAIL"
              placeholder="Email"
              required
            />
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_ea72f7c5bb4936598e5df3a54_18075db5fc"
                tabIndex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <SubmitButton
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              >
                Sign up
              </SubmitButton>
            </div>
          </Wrap>
        </form>
      </div>
    </>
  );
}

export default SignUp;

const Wrap = styled.div`
  height: 30px;
`;

const SubmitButton = styled.button`
  font-family: monserrat, sans-serif;
  height: 39px;
  background-color: #c38d9e;
  color: black;
  border: 1px black solid;
  width: 75px;
  margin-top: 10px;
  width: 100%;
  border-radius: 3px;

  :hover {
    cursor: pointer;
    background-color: rgb(195, 141, 158, 0.85);
  }

  @media (min-width: 576px) {
    width: 300px;
  }
`;
const EmailInput = styled.input`
  font-family: monserrat, sans-serif;
  border: 1px black solid;
  padding-left: 10px;
  height: 35px;
  width: 96%;
  border-radius: 3px;

  @media (min-width: 576px) {
    width: 286px;
  }
`;
