import React from 'react';
import styled from 'styled-components';

import HabitButtons from '../Components/HabitButtons';
import Journal from '../Components/Journal';
import { Button } from '../../styles/buttons';

function DailyAnchorComponent({
  saveAnchor,
  isSaving,
  todaysAnchor,
  handleUpdateAnchor,
  saveSuccess,
}) {
  function renderSaveButton() {
    if (saveSuccess) {
      return (
        <ButtonWrap>
          <SaveButton>Saved</SaveButton>
        </ButtonWrap>
      );
    }

    return (
      <ButtonWrap>
        <SaveButton isSaving={isSaving} onClick={() => saveAnchor()}>
          {isSaving ? <span>Saving...</span> : <span>Save</span>}
        </SaveButton>
      </ButtonWrap>
    );
  }

  function getTweet() {
    const { gratitudeOne, gratitudeTwo, gratitudeThree } = todaysAnchor;
    let tweet = `Todays gratitudes: ${gratitudeOne}`;

    gratitudeOne && gratitudeTwo
      ? (tweet = `Todays gratitudes: ${gratitudeOne}, ${gratitudeTwo}`)
      : (tweet = tweet);

    gratitudeOne && gratitudeTwo && gratitudeThree
      ? (tweet = `Todays gratitudes: ${gratitudeOne}, ${gratitudeTwo}, ${gratitudeThree}`)
      : (tweet = tweet);

    return tweet;
  }

  return (
    <Wrap>
      <HabitButtons
        todaysAnchor={todaysAnchor}
        handleUpdateAnchor={handleUpdateAnchor}
      />
      <Journal
        title="Gratitude"
        inputOne="gratitudeOne"
        inputTwo="gratitudeTwo"
        inputThree="gratitudeThree"
        handleUpdateAnchor={handleUpdateAnchor}
        valueOne={todaysAnchor.gratitudeOne}
        valueTwo={todaysAnchor.gratitudeTwo}
        valueThree={todaysAnchor.gratitudeThree}
      />
      <Journal
        title="Good"
        inputOne="goodOne"
        inputTwo="goodTwo"
        inputThree="goodThree"
        handleUpdateAnchor={handleUpdateAnchor}
        valueOne={todaysAnchor.goodOne}
        valueTwo={todaysAnchor.goodTwo}
        valueThree={todaysAnchor.goodThree}
      />
      {renderSaveButton()}
      {/* {todaysAnchor.gratitudeOne && (
        <TweetButton>
          <TweetLink
            class="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=${getTweet()}&hashtags=dailyanchor`}
            data-size="large"
          >
            Tweet
          </TweetLink>
        </TweetButton>
      )} */}
    </Wrap>
  );
}

export default DailyAnchorComponent;

const Wrap = styled.div`
  padding-top: 10px;
  @media (min-width: 576px) {
    justify-content: center;
    margin: 60px auto;
    max-width: 450px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

const SaveButton = styled(Button)`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ isSaving }) => (isSaving ? '#c38d9e;' : '#123c69;')};
`;

const TweetLink = styled.a``;

const TweetButton = styled(Button)`
  width: 98%;
  border-radius: 5px;
  background: #123c69;
  margin-top: 15px;
`;
