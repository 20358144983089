import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '../../styles/buttons';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <StyledButton
      type="button"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log Out
    </StyledButton>
  );
};

export default LogoutButton;

const StyledButton = styled(Button)`
  margin: unset;
`;
