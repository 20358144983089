import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import HabitButtons from '../Components/HabitButtons';
import Journal from '../Components/Journal';
import { Button } from '../../styles/buttons';

function FreeDailyAnchorComponent() {
  const blankAnchor = {
    gratitudeOne: null,
    gratitudeTwo: null,
    gratitudeThree: null,
    goodOne: null,
    goodTwo: null,
    goodThree: null,
    mindDone: false,
    moveDone: false,
    mellowDone: false,
  };
  const [isSaving, setIsSaving] = useState(false);
  const [todaysAnchor, setTodaysAnchor] = useState(blankAnchor);

  useEffect(() => {
    const storedAnchor = localStorage.getItem('todaysAnchor');

    if (storedAnchor) {
      const parsedAnchor = JSON.parse(storedAnchor);
      setTodaysAnchor(parsedAnchor);
    }
  }, []);

  function handleUpdateAnchor(type, value) {
    setTodaysAnchor({ ...todaysAnchor, [type]: value });
  }

  function saveAnchor() {
    setIsSaving(true);
    setTimeout(() => setIsSaving(false), 1500);
    localStorage.setItem('todaysAnchor', JSON.stringify(todaysAnchor));
  }

  function refreshAnchor() {
    setTodaysAnchor(blankAnchor);
    localStorage.setItem('journals', JSON.stringify(blankAnchor));
  }

  return (
    <>
      <Wrap>
        <HabitButtons
          handleUpdateAnchor={handleUpdateAnchor}
          todaysAnchor={todaysAnchor}
        />
        <Journal
          title="Gratitudes"
          inputOne="gratitudeOne"
          inputTwo="gratitudeTwo"
          inputThree="gratitudeThree"
          valueOne={todaysAnchor.gratitudeOne}
          valueTwo={todaysAnchor.gratitudeTwo}
          valueThree={todaysAnchor.gratitudeThree}
          handleUpdateAnchor={handleUpdateAnchor}
        />
        <Journal
          title="Good today"
          inputOne="goodOne"
          inputTwo="goodTwo"
          inputThree="goodThree"
          valueOne={todaysAnchor.goodOne}
          valueTwo={todaysAnchor.goodTwo}
          valueThree={todaysAnchor.goodThree}
          handleUpdateAnchor={handleUpdateAnchor}
        />
        <ButtonWrap>
          <SaveButton isSaving={isSaving} onClick={() => saveAnchor()}>
            {isSaving ? <span>Saved</span> : <span>Save</span>}
          </SaveButton>
          <RefreshButton onClick={() => refreshAnchor()}>Refresh</RefreshButton>
        </ButtonWrap>
      </Wrap>
      {/* <Upgrade>
        <UpgradeButton>Upgrade</UpgradeButton>
      </Upgrade> */}
    </>
  );
}

export default FreeDailyAnchorComponent;

const Wrap = styled.div`
  padding: 20px 5px;
  @media (min-width: 575px) {
    margin-top: 50px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const SaveButton = styled(Button)`
  width: 170px;
  :not(.disabled):not(:disabled):active,
  :not(.disabled):not(:disabled).active,
  :hover {
    background-color: #c38d9e;
    border-color: #c38d9e;
    border-radius: 0;
    border: 2px solid black;
  }
  @media (min-width: 575px) {
    width: 300px;
  }
`;

const RefreshButton = styled(Button)`
  width: 170px;
  @media (min-width: 575px) {
    width: 300px;
  }
`;

// const Upgrade = styled.div`
//   position: fixed;
//   background: grey;
//   bottom: 0;
// `;

// const UpgradeButton = styled.div``;
