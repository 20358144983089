import React from 'react';
import { hot } from 'react-hot-loader';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import GlobalStyle from './styles/Globalstyles';
import DailyAnchorPage from './App/Pages/DailyAnchorPage';
import Guide from './App/Pages/Guide';
import Drawer from './App/Components/Drawer';
import Home from './App/Pages/Home';
import Stats from './App/Pages/Stats';
import AuthenticatedRoute from './App/Components/AuthenticatedRoute';

function App() {
  const { user, isAuthenticated, isLoading } = useAuth0();

  // should we really do this here?
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <GlobalStyle />
      <Wrap>
        {isAuthenticated && <Drawer />}
        <Switch>
          {!isAuthenticated && <Route path="/" exact component={Home} />}
          <AuthenticatedRoute path="/anchor" component={DailyAnchorPage} />
          <AuthenticatedRoute path="/stats" exact component={Stats} />
          <AuthenticatedRoute path="/guide" component={Guide} />
        </Switch>
      </Wrap>
    </>
  );
}

export default hot(module)(App);

const Wrap = styled(Container)`
  @media (max-width: 575px) {
    padding: 20px;
  }
`;
