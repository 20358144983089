import React from 'react';
import styled from 'styled-components';

function Guide() {
  return (
    <Wrap>
      <Title>Daily anchor</Title>
      <Subtext>
        Daily anchor is an action tracker to help you build positive wellbeing
        habits. I've been writing down a daily anchor since around 2016 on paper
        and on the days I keep track of specific actions and complete the tasks
        I feel more relaxed, in control and dare I say happier.
      </Subtext>
      <Subtext>
        Daily anchor is all about action. It's a structure or guide for you to
        take action. As without action nothing changes. The actions within daily
        anchor are either physical (move, mind, mellow) in order to change your
        physical state or thinky (gratitudes + good) in order to change your
        thinky state. I've only selected key actions that have been proven to
        increase a sense of wellbeing.
      </Subtext>
      <Subtitle>Move</Subtitle>
      <Subtext>
        Perform any sort of movement, but ideally something that raises the
        heart rate. Check this off when you've done any sort movement or
        exercise for 15 mins +.
        <ListGroup>
          <List>Walking</List>
          <List>Weights</List>
          <List>Cycling</List>
          <List>Circuits</List>
          <List>Yoga</List>
        </ListGroup>
      </Subtext>
      <Subtitle>Mind</Subtitle>
      <Subtext>
        Perform a mindful activity such as focussed breathing, mindfulness or
        just nothing time. I use the headspace app for this. But there are many
        (calm, 10% happier, waking up) or watch a free youtube mindfulness
        session.
        <ListGroup>
          <List>Meditation</List>
          <List>Breathing by self</List>
          <List>Nothing time</List>
          <List>Flow activities</List>
        </ListGroup>
      </Subtext>
      <Subtitle>Mellow</Subtitle>
      <Subtext>
        This is all about setting the stage for good sleep. Take 30-60 mins
        before bed to mellow. Perform at least one mellow action (something that
        calms you down).
        <ListGroup>
          <List>Bath</List>
          <List>Reading</List>
          <List>Yoga, stretching, self massage</List>
          <List>Accupressure, shakti mat</List>
        </ListGroup>
      </Subtext>
      <Subtitle>Gratitude</Subtitle>
      <Subtext>
        Anytime in the day jot down 3 things your grateful or thankful for. Aim
        for a mix of big and small things. Eg, good sleep, coffee, having a
        house, living in peace. A sense of gratitude has been linked to good
        wellbeing.
      </Subtext>
      <Subtitle>Good today</Subtitle>
      <Subtext>
        This is an exercise in finding the brightspots ie focussing and
        remembering the positives. At the end of the day, list out the key
        things that went well, were positive or you were happy about. Again they
        can be big or small. Eg, got alot of work done, saw friends, did
        exercise etc.
      </Subtext>
    </Wrap>
  );
}

export default Guide;

const Wrap = styled.div`
  padding-top: 20px 0;
  @media (min-width: 576px) {
    justify-content: center;
    margin: 60px auto;
    max-width: 450px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.25em;

  @media (max-width: 575px) {
    display: none;
  }
`;

const Subtext = styled.div`
  font-size: 0.875em;
  color: #000000;
  margin-top: 10px;

  @media (min-width: 575px) {
    font-size: 1em;
  }
`;

const Subtitle = styled.div`
  font-weight: 700;
  font-size: 1em;
  margin-top: 20px;
`;

const List = styled.li`
  margin-left: -15px;
`;

const ListGroup = styled.ul`
  margin-top: 15px;
`;
