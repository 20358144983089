import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

import hamburger from '../../styles/lotties/40209-hamburger-with-colors.json';
import LogoutButton from './LogoutButton';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hamburger,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      <List>
        <ListItem>
          <StyledLink to="/anchor">
            <ListItemText>Anchor</ListItemText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/stats">
            <ListItemText>Stats</ListItemText>
          </StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink to="/guide">
            <ListItemText>Guide</ListItemText>
          </StyledLink>
        </ListItem>
        {/* Only show if user if inactive */}
        {/* <ListItem>
          <StyledLink to="/subscribe">
            <ListItemText>Subscribe</ListItemText>
          </StyledLink>
        </ListItem> */}
        {/* <ListItem>
          <StyledLink to="/support">
            <ListItemText>Support</ListItemText>
          </StyledLink>
        </ListItem> */}
        <ListItem>
          <LogoutButton />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Header>
          <AnchorLogo>Daily anchor</AnchorLogo>
          <Hamburger>
            <Button onClick={toggleDrawer('right', true)}>
              <MenuIcon />
              {/* <Lottie options={defaultOptions} height={40} width={40} /> */}
            </Button>
          </Hamburger>
        </Header>
        <SwipeableDrawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
        >
          {list('right')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

const Hamburger = styled.div`
  /* position: absolute;
  left: 301px; */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const AnchorLogo = styled.div`
  padding-top: 6px;
  font-size: 1.125em;
  font-weight: 700;
`;
