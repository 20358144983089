import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import DailyAnchorComponent from './DailyAnchorComponent';
import { postAnchor, getTodaysAnchor, updateTodaysAnchor } from '../../api';

function DailyAnchorContainer() {
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [todaysAnchor, setTodaysAnchor] = useState({
    gratitudeOne: null,
    gratitudeTwo: null,
    gratitudeThree: null,
    goodOne: null,
    goodTwo: null,
    goodThree: null,
    mindDone: false,
    moveDone: false,
    mellowDone: false,
    userId: null,
  });

  const { user, isAuthenticated, isLoading } = useAuth0();
  const userId = user.sub;

  useEffect(() => {
    if (userId) {
      getTodaysAnchor(userId).then((response) => {
        if (response) {
          setTodaysAnchor(response);
        }
      });
    }
  }, [userId, setTodaysAnchor]);

  function saveAnchor() {
    setIsSaving(true);
    if ('id' in todaysAnchor) {
      updateTodaysAnchor(todaysAnchor).then((res) => {
        if (res.status === 201) {
          setIsSaving(false);
          setSaveSuccess(true);
          setTimeout(() => setSaveSuccess(false), 3000);
        }
      });

      return;
    }

    delete todaysAnchor.id;

    if (userId) todaysAnchor.userId = userId;
    postAnchor(todaysAnchor).then((response) => {
      if (response.status === 201) {
        setIsSaving(false);
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000);
      }
    });
  }

  function handleUpdateAnchor(type, value) {
    setTodaysAnchor({ ...todaysAnchor, [type]: value });
  }

  return (
    <DailyAnchorComponent
      saveAnchor={saveAnchor}
      mindDone={todaysAnchor.mindDone}
      moveDone={todaysAnchor.moveDone}
      mellowDone={todaysAnchor.mellowDone}
      handleUpdateAnchor={handleUpdateAnchor}
      isSaving={isSaving}
      todaysAnchor={todaysAnchor}
      saveSuccess={saveSuccess}
    />
  );
}

export default DailyAnchorContainer;
