import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../../styles/buttons';

function HabitButtons({ todaysAnchor, handleUpdateAnchor }) {
  function renderButton(text, checkedOff, type) {
    if (checkedOff) {
      return (
        <DoneButton onClick={() => handleUpdateAnchor(type, !checkedOff)}>
          {text}
          <TickIcon icon={faCheck} />
        </DoneButton>
      );
    }

    return (
      <TodoButton onClick={() => handleUpdateAnchor(type, !checkedOff)}>
        {text}
      </TodoButton>
    );
  }

  return (
    <ButtonWrap>
      {renderButton('Move', todaysAnchor.moveDone, 'moveDone')}
      {renderButton('Mind', todaysAnchor.mindDone, 'mindDone')}
      {renderButton('Mellow', todaysAnchor.mellowDone, 'mellowDone')}
    </ButtonWrap>
  );
}

export default HabitButtons;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const TodoButton = styled(Button)`
  width: 105px;
  border-radius: 5px;
  margin: 0 4px;

  @media (min-width: 575px) {
    width: 200px;
  }
`;

const DoneButton = styled(Button)`
  background-color: #c38d9e;
  border-color: #c38d9e;
  width: 105px;
  border-radius: 5px;
  margin: 0 4px;

  @media (min-width: 575px) {
    width: 200px;
  }
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  font-size: 0.75em;
`;
