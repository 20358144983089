import axios from 'axios';

const base = process.env.REACT_APP_BASE_URL;

export const postAnchor = async (anchor) => {
  const response = await axios.post(`${base}anchor`, {
    anchor,
  });
  return response;
};

export const getTodaysAnchor = async (userId) => {
  const { data } = await axios.get(`${base}anchor/${userId}`);
  return data.anchor;
};

export const updateTodaysAnchor = async (anchor) => {
  const response = await axios.put(`${base}anchor`, {
    anchor,
  });
  return response;
};

export const getUsersHistory = async (userId) => {
  const { data } = await axios.get(`${base}anchor/history/${userId}`);
  return data;
};

export const createPaymentIntent = async () => {
  const response = await axios.post(`${base}create-payment-intent`, {
    items: [{ id: 'anchor-sub', price: 200 }],
  });
  return response;
};
