import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '../../styles/buttons';
import Anchor from '../../Assets/anchor.png';
import SignUp from '../Components/SignUp';

function Home() {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <Header>
        <Logo>Daily anchor</Logo>
      </Header>
      <Wrap>
        <Title>Your Personal Wellbeing Toolkit.</Title>
        <Subtext>
          Staying on top of your personal wellbeing is hard. Daily Anchor helps
          you to take action on the areas that matter most to your wellbeing.
        </Subtext>
        <SignUpWrap>
          <SignUpLabel>Opening soon. Stay in contact</SignUpLabel>
          <SignUp />
        </SignUpWrap>
        {/* <ButtonWrap>
          <SignUpButton type="button" onClick={() => loginWithRedirect()}>
            Get started for free
          </SignUpButton>
        </ButtonWrap> */}
        <PicWrap>
          <AnchorPic src={Anchor} alt="anchor-pic" />
        </PicWrap>
        <BigSubtitle>
          Track your daily wellbeing on one page in 5 mins a day.
        </BigSubtitle>
        <Text>
          Increase accountability for your actions with a home base for your
          wellbeing.
        </Text>
        <Text>
          Change how you feel with actions that impact your physical and mental
          state.
        </Text>
        <Text>Practise gratitude to grow your happiness.</Text>
      </Wrap>
    </>
  );
}

export default Home;

const Wrap = styled(Container)`
  justify-content: center;
  margin: 60px auto;
  max-width: 600px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 5px;
  font-weight: 700;
  color: rgb(75, 76, 77);
`;

const Logo = styled.div``;

const Title = styled.div`
  font-size: 2.75em;
  font-weight: 700;
  color: #1e272e;
  line-height: 50px;

  @media (min-width: 576px) {
    text-align: center;
  }
`;

const Subtext = styled.div`
  font-size: 1.25em;
  color: rgb(75, 76, 77);
  padding-top: 25px;
  padding-bottom: 40px;
  line-height: 26px;

  @media (min-width: 576px) {
    text-align: center;
  }
`;

const Text = styled.div`
  font-size: 1.25em;
  color: rgb(75, 76, 77);
  padding-top: 20px;
  line-height: 26px;

  @media (min-width: 576px) {
    text-align: center;
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
`;

const SignUpWrap = styled.div`
  text-align: center;
  padding-bottom: 60px;
`;

const SignUpButton = styled(Button)`
  font-weight: 700;
  padding: 12px 18px;
  height: 42px;
  width: 100%;
  font-size: 1em;

  @media (min-width: 576px) {
    width: 300px;
  }
`;

const BigSubtitle = styled.div`
  font-size: 1.75em;
  font-weight: 700;
  color: rgb(75, 76, 77);
  margin: 40px 0 0;
  color: #1e272e;

  @media (min-width: 576px) {
    text-align: center;
  }
`;

const PicWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
`;

const AnchorPic = styled.img`
  width: 52%;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const SignUpLabel = styled.div`
  font-weight: 0.875em;
  margin-bottom: 4px;
`;
