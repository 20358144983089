import React from 'react';
import styled from 'styled-components';
import { InputGroup, FormControl } from 'react-bootstrap';

function Journal({
  handleUpdateAnchor,
  title,
  inputOne,
  inputTwo,
  inputThree,
  valueOne,
  valueTwo,
  valueThree,
}) {
  return (
    <Wrap>
      <Title>{title}</Title>
      <Input className="mb-3">
        <StyledFormControl
          placeholder="1"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={(e) => handleUpdateAnchor(inputOne, e.target.value)}
          defaultValue={valueOne}
        />
      </Input>
      <Input className="mb-3">
        <StyledFormControl
          placeholder="2"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={(e) => handleUpdateAnchor(inputTwo, e.target.value)}
          defaultValue={valueTwo}
        />
      </Input>
      <Input className="mb-3">
        <StyledFormControl
          placeholder="3"
          aria-label="Username"
          aria-describedby="basic-addon1"
          onChange={(e) => handleUpdateAnchor(inputThree, e.target.value)}
          defaultValue={valueThree}
        />
      </Input>
    </Wrap>
  );
}

export default Journal;

const Wrap = styled.div``;

const Title = styled.div`
  margin-top: 40px;
  font-size: 1.125em;
  font-weight: 700;
`;

const Input = styled(InputGroup)`
  margin-top: 15px;

  .form-control:focus {
    background-color: #e8e4df;
  }
`;

const StyledFormControl = styled(FormControl)`
  border-radius: 0;
  border-bottom: 1px solid grey !important;
  border: 1px solid transparent;
  background-color: #e8e4df;
  width: 95%;
  padding-bottom: 6px;
`;
