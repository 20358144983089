import styled from 'styled-components';
import { Button as bootstrapButton } from 'react-bootstrap';

export const Button = styled(bootstrapButton)`
  background-color: #123c69;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  height: 33px;
  color: #ffffff;

  :hover {
    background-color: #c38d9e;
    border-radius: 5px;
    border: none;
  }

  :focus {
    background-color: #123c69;
  }
`;

export const OutlineButton = styled(bootstrapButton)`
  background-color: transparent !important;
  border-color: #123c69 !important;
`;
