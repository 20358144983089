import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import StatsComponent from './StatsComponent';
import { getUsersHistory, getUsersStats } from '../../api';

function StatsContainer() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [stats, setStats] = useState();
  const userId = user.sub;

  useEffect(() => {
    if (userId) {
      getUsersHistory(userId).then((response) => {
        if (response) {
          setStats(response);
        }
      });
    }
  }, [userId, setStats]);

  return <StatsComponent stats={stats} />;
}

export default StatsContainer;
